import Standings from "../Standings/Route"
import "./hof.css"



export default function HallOfShame() {
    
    return (
        <Standings shame = {true} />
    )
}